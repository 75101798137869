<template>
  <div
    class="logo-container"
    @click="click"
    :class="{ clickable: hasClickListener, 'not-clickable': !hasClickListener }"
  >
    <span id="logo">{{ siteName }}</span>
    <img id="logo-mark" src="/img/CareCompare-logomark.svg" alt="" />
  </div>
</template>

<script>
import { SITE } from "@/config"
export default {
  computed: {
    siteName() {
      return `${SITE.toLowerCase()}Compare`
    },
    hasClickListener() {
      return this.$listeners && this.$listeners.click
    }
  },
  methods: {
    click() {
      this.$emit("click")
    }
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}
.logo-container {
  align-self: flex-end;
  justify-self: end;
  display: flex;
  height: 110px;
  position: relative;
  text-decoration: none;
}

#logo {
  padding-left: 2px;
  font-weight: 400;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: var(--v-grey-base);
  font-size: 2em;
  padding-top: 48px;
  text-decoration: none;
  letter-spacing: -1px;
  text-transform: capitalize;
  color: var(--v-grey-base);
}

#logo-mark {
  margin-bottom: 1.5em;
  margin-right: 5px;
  height: 3em;
  align-self: center;
  margin-left: -5px;
}

@media only screen and (max-height: 720px) {
  #logo {
    align-self: flex-end;
  }
  #logo-mark {
    align-self: flex-end;
  }
}

@media only screen and (max-width: 767px) {
  #logo {
    font-size: 1.25em;
  }
  #logo-mark {
    height: calc(3em * 0.625);
  }
}
</style>
